@font-face {
  font-family: Nunito Sans-Regular;
  src: url(./shared/styles/Nunito_Sans/NunitoSans-Regular.ttf);
}

@font-face {
  font-family: Nunito Sans-Black;
  src: url(./shared/styles/Nunito_Sans/NunitoSans-Black.ttf);
}

@font-face {
  font-family: Nunito Sans-Bold;
  src: url(./shared/styles/Nunito_Sans/NunitoSans-Bold.ttf);
}

@font-face {
  font-family: Nunito Sans-SemiBold;
  src: url(./shared/styles/Nunito_Sans/NunitoSans-SemiBold.ttf);
}

body {
  font-family: 'Nunito Sans-SemiBold', sans-serif !important;
  margin: 0;
}
